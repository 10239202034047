@media (min-width: 320px) {
  .navbar-container {
    width: 100%;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    position: fixed;
  }

  .navbar-container h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-size: 13px;
  }

  .navbar-container a {
    text-decoration: none;
    color: black;
  }

  .navbar-main-link {
    font-family: "Roboto Slab", serif;
    font-size: 22px;
    margin-left: 10px;
  }

  .navbar-sub-link-container {
    margin-right: 10px;
    font-family: "Roboto", sans-serif;
  }
}
@media (min-width: 961px) {
  .navbar-container {
    width: 100vw;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    position: fixed;
  }

  .navbar-container h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    padding: 0 30px 0 30px;
    font-size: 20px;
  }

  .navbar-container a {
    text-decoration: none;
    color: black;
    margin: 0 30px 0 30px;
  }

  .navbar-main-link {
    font-family: "Roboto Slab", serif;
    font-size: 22px;
    margin-left: 10px;
  }

  .navbar-sub-link-container {
    margin-right: 10px;
    font-family: "Roboto", sans-serif;
  }
}
