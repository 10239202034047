@media (min-width: 320px) {
  .portada-img {
    width: 80vw;
    border-radius: 10px;
  }
  .biography-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }


  .biography-info h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 23px;
    line-height: 30px;
  }

  .biography-info h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    width: 80vw;
  }
}
@media (min-width: 961px) {
  .portada-img {
    width: 35vw;
    border-radius: 10px;
  }

  .biography-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .biography-info {
    width: 100%;
    padding: 0 50px 100px 50px;
  }

  .biography-info h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 30px;
  }

  .biography-info h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    width: 50vw;
  }
}
