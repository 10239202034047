@media (min-width: 320px) {
  .news-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .news-title {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 23px;
    letter-spacing: 3px;
  }

  .single-news-container {
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
  }

  .single-article-container {
    display: flex;
    flex-direction: column;
  }

  .single-article-text-container {
    margin: 0 10px 0 10px;
    text-align: center;
  }

  .single-article-text-container h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    letter-spacing: 5px;
    font-size: 23px;
    line-height: 30px;
  }

  .single-article-text-container h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
  }

  .single-news-image {
    width: 100vw;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .border-radius-inverted-container {
    width: 125px;
    height: 125px;
    border-radius: 100%;
    background-color: #f5f5f5;
    position: relative;
    top: 50%;
  }

  .single-news-link-image {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 30%;
    left: 9.5%;
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
  }
}
@media (min-width: 961px) {
  .news-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

  .news-title {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 6px;
  }

  .single-news-container {
    width: 50vw;
    height: 20vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
  }

  .single-article-container {
    display: flex;
    flex-direction: row;
  }

  .single-article-text-container {
    margin-left: 50px;
  }

  .single-article-text-container h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    letter-spacing: 5px;
    font-size: 23px;
    line-height: 30px;
  }

  .single-article-text-container h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
  }

  .single-news-image {
    width: 20vw;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 0;
  }

  .border-radius-inverted-container {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #f5f5f5;
    position: relative;
    top: 40%;
    left: 3%;
  }

  .single-news-link-image {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20%;
    left: 20%;
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
