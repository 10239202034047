.button-title {
  font-family: "Nanum Myeongjo", serif;
  font-size: 15px;
  border: none;
  background-color: transparent;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.drawer-title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-reviews-container {
  display: flex;
  flex-direction: column;
}

.review-text {
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  text-align: justify;
  font-weight: 500;
  bottom: 20px;
}

.net-link-text {
  font-family: "Cormorant Garamond", serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 15px;
}

.review-author-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  bottom: 20px;
}

.review-author {
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  position: relative;
  bottom: 40px;
  font-weight: 500;
  line-height: 30px;
}

.review-year {
  font-family: "Nanum Myeongjo", serif;
  font-size: 15px;
  color: #494949;
  font-weight: 800;
}

.drawer-title-button {
  background-color: transparent;
  border: none;
}

.drawer-title {
  font-family: "Nanum Myeongjo", serif;
  font-weight: 900;
  letter-spacing: 2px;
}

.network-facebook-icon-author {
  color: #3b5998;
  position: relative;
  bottom: 5px;
}

.network-icon-author {
  color: #e2e2e2;
  position: relative;
  bottom: 5px;
}
