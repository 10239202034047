@media (min-width: 320px) {
  .carrousel-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carrousel-section h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    font-size: 23px;
    line-height: 30px;
  }

  .carrousel-section div {
    display: flex;
    align-items: center;
  }

  .carrousel-section div button {
    border-radius: 100%;
    border: none;
    background-color: transparent;
    width: 30px;
    margin: 0 20px 0
  }

  .button-active {
    color: black;
  }

  .button-inactive {
    color: #d1d1d1;
  }
}

@media (min-width: 961px) {
  .carrousel-section {
    width: 100vw;
    height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carrousel-section h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 3px;
  }

  .carrousel-section div {
    display: flex;
    align-items: center;
  }

  .carrousel-section div button {
    margin: 0 50px 0 50px;
    padding: 30px;

    border-radius: 100%;
    border: none;
    background-color: transparent;
  }

  .button-active {
    color: black;
  }

  .button-inactive {
    color: #d1d1d1;
  }
}
