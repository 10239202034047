@media (min-width: 320px) and (max-width: 960px) {
  .list-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .list-container h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 23px;
    letter-spacing: 3px;
  }

  .book-image {
    width: 60vw;
    margin: auto;
    display: block;
    padding-top: 20px;
  }

  .sinopsis-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .sinopsis-container h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 40px;
    width: 90vw;
    text-align: start;
  }

  .svg-image {
    width: 100px;
  }

  .fnac-image {
    width: 100px;
  }

  .redirect-links-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .redirect-links-container a {
    margin: 10px;
  }

  .button-collapse {
    border: none;
    background-color: transparent;
    font-size: 20px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    letter-spacing: 3px;
  }

  .sinopsis-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sinopsis-title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 3px;
    width: 70%;
    margin: 20px 0 20px 0;
    text-align: center;
    line-height: 40px;
  }

  .sinopsis-subtitle {
    font-family: 'Lobster', cursive;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 3px;
    width: 70%;
    margin: 20px 0 20px 0;
    text-align: center;
    line-height: 40px;
  }

  .book-container {
    background-color: white;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .sinopsis-review-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 961px) {
  .list-container {
    margin: 0 50px 100px 50px;
  }

  .list-container h1 {
    font-family: "Penumbra Web Pro", sans-serif;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 6px;
    text-align: center;
  }

  .sinopsis-container {
    display: flex;
    flex-direction: row;
  }

  .sinopsis-subcontainer h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 40px;
    margin-left: 50px;
    width: 65vw;
  }

  .svg-image {
    width: 150px;
  }

  .fnac-image {
    width: 100px;
  }

  .redirect-links-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .redirect-links-container a {
    margin: 0 50px 0 50px;
  }

  .button-collapse {
    border: none;
    background-color: transparent;
    font-size: 20px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    letter-spacing: 3px;
  }

  .sinopsis-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .book-container {
    background-color: white;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .book-image {
    width: 15vw;
    padding: 20px;
  }

  .sinopsis-title {
    font-family: "Cormorant Garamond", serif;
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 3px;
    width: 70%;
    margin: 20px 0 20px 0;
    text-align: center;
    line-height: 30px;
  }

  .sinopsis-subtitle {
    font-family: 'Lobster', cursive;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 3px;
    width: 70%;
    margin: 20px 0 20px 0;
    text-align: center;
    line-height: 40px;
  }

  .sinopsis-review-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
